<template>
  <div class="box" v-runshow>
     <div style="text-align:center;">
       <div class="title">
         精细化慢病管理
       </div>
       <div class="desc">
         协助医院在线上完成患者信息的收集与分析  并依托平台提供个性化的精细化服务以及患者群体运营
       </div>
       <div class="tips">

       </div>
     </div>
     <!--  -->
     <div  class=" ctx flex j_c_a  a_i_s">
       <div class="text">
         通过健康数据采集、健康数据监控和分析、健康服务接入等措施实现健康目标达成，完成一整套健康干预服务闭环，依据患者健康状态的变化，线上线下相结合推动随诊复诊，线上线下相互循环，不断完善对慢病患者的健康干预过程。
       </div>
       <img v-runcentershow class="img" :src="require('@/assets/ChronicDisease/com.png')" alt="">
     </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.box{
  padding: 1.2rem 1.3rem;
  background: #fff;
}
.img{
  width: 7.62rem;
}
.title{
  margin-bottom: 0.16rem;
  font-weight: 900;
  font-size: 0.3rem;
  line-height: 0.42rem;
}
.desc{
  font-weight: 400;
  font-size: 0.38rem;
  line-height: 0.53rem

}
.tips{
  height: 3px;
  width: 80px;
  background: #1AA858;
  margin: 10px auto;
  margin-bottom: 40px;
}
.text{
  max-width: 4rem;
  color: #666;
  font-size: 0.22rem;
  line-height: 0.36rem;

}


@media screen and (max-width:1024px) {
  .ctx{
    flex-direction:column-reverse;
    flex-wrap: wrap;
  }
  .box{
    padding: 30px 20px;
  }
  .img{
    width: 100%;
    margin-bottom: 20px;
  }
  .tips{
    margin-bottom: 20px;
  }
  .text{
    padding: 0;
    max-width: 100%;
    font-size: 0.32rem;
    line-height: 0.48rem;
  }
  .title,.desc{
    font-size: 0.38rem;
    line-height: 0.58rem;
  }
}

/* @media screen and (min-width:1910px) {
  .text{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    margin-left: 8  0px;
  }
  
} */

</style>